import { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS for default animations
import ThemeRoutes from "./routes/Router";

const App = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  // Define the authentication state
  const [authState, setAuthState] = useState(() => {
    const stored_access_token = localStorage.getItem('access_token');
    const stored_refresh_token = localStorage.getItem('refresh_token');
    return {
      isAuthenticated: !!stored_access_token,
      access_token: stored_access_token || null,
      refresh_token: stored_refresh_token || null,
    };
  });

  // Function to update the authentication state
  const updateAuthState = (access_token, refresh_token) => {
    setAuthState({
      isAuthenticated: true,
      access_token,
      refresh_token,
    });
  };

  // Logout function to clear the authentication state
  const logout = () => {
    setAuthState({
      isAuthenticated: false,
      access_token: null,
      refresh_token: null,
    });

    // Clear stored tokens (if any)
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  };

  const routing = useRoutes(ThemeRoutes(authState, updateAuthState, logout));

  return <div className="dark">{routing}</div>;
};

export default App;

import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/
const Home = lazy(() => import("../views/frontend/Home.js"));
const Starter = lazy(() => import("../views/backend/Starter.js"));
const Login = lazy(() => import("../views/backend/Login.js"));
const Feedback = lazy(() => import("../views/backend/Feedback.js"));

/*****Routes******/

const ThemeRoutes = (authState, updateAuthState, logout) => [
  {
    path: "/",
    children: [{ path: "/", exact: true, element: <Home /> }],
  },
  {
    path: "/admin",
    children: [
      {
        path: "/admin/login",
        exact: true,
        element: authState.isAuthenticated ? (
          <Navigate to="/admin/starter" />
        ) : (
          <Login updateAuthState={updateAuthState} />
        ),
      },
    ],
  },
  {
    path: "/admin",
    element: authState.isAuthenticated ? (
      <FullLayout logout={logout}/>
    ) : (
      <Navigate to="/admin/login" />
    ),
    children: [
      { path: "/admin", element: <Navigate to="/admin/starter" /> },
      { path: "/admin/starter", exact: true, element: <Starter updateAuthState={updateAuthState} /> },
      { path: "/admin/feedback", exact: true, element: <Feedback updateAuthState={updateAuthState} /> },
    ],
  },
];

export default ThemeRoutes;
